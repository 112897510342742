import { DataAccess } from "../../dataaccess/data.access";
import { Timeout } from "../../decorators/timeout.decorator";
import { AdvancedSearchUtil } from "../../modules/advanced-search/advanced-search-util";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { BpeService } from "../../modules/bpe/bpe.service";
import { EventData, EventService } from "../event.service";
import { LangService } from "../lang.service";
import { ListService } from "../list.service";
import { UserprefService } from "../userpref.service";
import { TaskSelectionMap } from "./task.selection.map";
import { EventStateChangeService } from "../event.state.change.service";
import { Task } from "../../pojo/Task";
import { TaskEditService, TaskUpdateI } from "./task.edit.service";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { Proto } from "../../pojo/Proto";
import NumericalString = Proto.NumericalString;
import { TaskNormalizeUtil } from "./task.normalize.util";
import { Event as S25Event } from "../../pojo/Event";
import Contact = S25Event.Workflow.Contact;
import { TaskTiersService } from "./task.tiers.service";
import { PreferenceService } from "../preference.service";
import { AlertService } from "../alert.service";
import StateName = Task.StateName;

export interface EventTaskStateChange {
    alertText: string;
    success: boolean;
    error: string;
    overallState: Task.State;
    item: Task.Object;
}

export interface EventTaskListStateChange extends EventTaskStateChange {
    success: boolean & { contacts: S25Event.Workflow.Contact[] };
    item: Task.Object & {
        itemStateId: Task.State;
        overallStateId: Task.State;
        overallStateName: string;
        itemCount: number;
        itemTypeId: number;
        itemId: number;
        isTodo: boolean;
        callback: Function;
    };
}

export interface TaskChange {
    eventId: number;
    itemId: number; //TaskId
    itemTypeId: Task.Id;
    objectType?: number;
    objectId?: number;
    isTodo: boolean;
    todoType: Task.Todo.Type;
    todoSubType: Task.SubTypes.cancel;
    itemStateId: Task.State;
    itemCount?: number; //Indicates how many other tasks are on the event and object
}

//Use this to see avoid acting on the same event 2 times in a row.
let taskActionQueue: {
    [eventActionHash: string]: {
        taskIds: Set<Number>;
        items?: TaskChange[];
        inProgressPromise: Promise<any>;
    };
} = {};

let checkAndUpdateTaskActionsQueue = function (
    eventId: number,
    newState: Task.States,
    taskIds: Set<Number>,
    promise: Promise<any>,
) {
    let eventActionHash = eventId + "-" + newState;
    if (taskActionQueue[eventActionHash]) {
        taskIds.forEach((taskId) => {
            taskActionQueue[eventActionHash].taskIds.add(taskId);
        });
    } else {
        taskActionQueue[eventActionHash] = {
            taskIds: taskIds,
            inProgressPromise: promise,
        };
    }

    return taskActionQueue[eventActionHash];
};

export class TaskService {
    /* GETs */
    @Timeout
    public static getTasks(params: any) {
        if (!params.taskBean) {
            //dao requires a separate taskBean
            params.taskBean = params;
        }

        let url = "/tasks.json";
        url += "?user_id=" + params.taskBean.currentContactId;
        url += params.taskBean.assignedTo ? "&assigned_to=" + params.taskBean.assignedTo : "";
        url += params.taskBean.assignedBy ? "&assigned_by=" + params.taskBean.assignedBy : "";
        url += params.taskBean.onlyUnread ? "&unread=T" : "";
        url += params.taskBean.onlyOutstanding ? "&outstanding=T" : "";
        url += params.taskBean.taskStateArray ? "&task_state=" + params.taskBean.taskStateArray.join("+") : "";
        url += params.taskBean.taskTypeArray ? "&task_type=" + params.taskBean.taskTypeArray.join("+") : "";
        url += params.taskBean.dueStartDt ? "&due_start_dt=" + params.taskBean.dueStartDt : "";
        url += params.taskBean.dueEndDt ? "&due_end_dt=" + params.taskBean.dueEndDt : "";
        url += params.taskBean.queryId ? "&query_id=" + params.taskBean.queryId : "";
        url += params.taskBean.scope ? "&scope=" + params.taskBean.scope : "";
        url += params.taskBean.eventId ? "&event_id=" + params.taskBean.eventId : "";

        //elements injected by List
        if (!params.taskBean.isCount && params.modelBean) {
            url += params.modelBean.itemsPerPage ? "&page_size=" + params.modelBean.itemsPerPage : "";
            url += params.modelBean.chosen.page ? "&page=" + params.modelBean.chosen.page : "";
            url += params.modelBean.cacheId ? "&paginate=" + params.modelBean.cacheId : "&paginate";
            if (params.modelBean.sortCol) {
                url += params.modelBean.sortCol.sort ? "&sort=" + params.modelBean.sortCol.sort : "";
                url += params.modelBean.sortCol.order ? "&order=" + params.modelBean.sortCol.order : "";
            } else {
                url += "&sort=asc&order=task_item";
            }
        }

        if (params.taskBean.isCount) {
            url += "&scope=count";
        }

        return DataAccess.get(DataAccess.injectCaller(url, "TaskService.getTasks")).then(function (data) {
            data = S25Util.prettifyJson(data, null, { task: true });

            if (data && data.tasks && data.tasks.task) {
                //see: http://bugs.collegenet.com/browse/STORY-2519 for details
                jSith.forEach(data.tasks.task, function (_, task) {
                    task.event_name = S25Util.toStr(task.event_name);
                    if (task && task.event_name && task.event_name.search(/\/ rsrv_/gi) > -1) {
                        task.event_name = task.event_name.substring(0, task.event_name.search(/\/ rsrv_/gi) - 1);
                    } else if (task && task.first_date === "") {
                        //Remove empty first_dates to avoid console logs
                        delete task.first_date;
                    }
                });
            }

            return S25Util.replaceDeep(data, {
                creation_date: S25Util.date.dropTZString,
                first_date: S25Util.date.dropTZString,
                respond_by: S25Util.date.dropTZString,
                due_date: S25Util.date.dropTZString,
            });
        });
    }

    @Timeout
    public static async getTasks2(options: {
        query: string;
        pageSize?: number;
        page?: number;
        cacheId?: number;
        sortColumn?: string;
        sortOrder?: "asc" | "desc";
        isCount?: boolean;
    }) {
        const userId = await UserprefService.getContactId();
        const { query, pageSize, page, cacheId, sortColumn, sortOrder } = options;

        const params = [
            `user_id=${userId}`,
            query.replace(/^&/, ""),
            `page_size=${pageSize || 25}`,
            `page=${page || 1}`,
            `paginate=${cacheId || ""}`,
            `sort=${sortColumn || "status"}`,
            `order=${sortOrder || "desc"}`,
        ];
        if (options.isCount) params.push("scope=count");

        const url = `/tasks.json?` + params.join("&");
        const raw = await DataAccess.get(DataAccess.injectCaller(url, "TaskService.getTasks"));
        const data = S25Util.prettifyJson(raw, null, { task: true });

        if (data?.tasks?.task) {
            //see: http://bugs.collegenet.com/browse/STORY-2519 for details
            jSith.forEach(data.tasks.task, function (_, task) {
                task.event_name = S25Util.toStr(task.event_name);
                if (task && task.event_name && task.event_name.search(/\/ rsrv_/gi) > -1) {
                    task.event_name = task.event_name.substring(0, task.event_name.search(/\/ rsrv_/gi) - 1);
                } else if (task && task.first_date === "") {
                    //Remove empty first_dates to avoid console logs
                    delete task.first_date;
                }
            });
        }

        return S25Util.replaceDeep(data, {
            creation_date: S25Util.date.dropTZString,
            first_date: S25Util.date.dropTZString,
            respond_by: S25Util.date.dropTZString,
            due_date: S25Util.date.dropTZString,
        });
    }

    @Timeout
    public static getTasksBySearchQuery(searchQuery: string, includes?: string[], dataScope?: string) {
        if (includes && includes.length) {
            dataScope = "extended";
        }
        let url = "/tasks.json";
        url += dataScope ? (url.indexOf("?") > -1 ? "&" : "?") + "scope=" + dataScope : "";
        url += includes && includes.length ? (url.indexOf("?") > -1 ? "&" : "?") + "include=" + includes.join("+") : "";
        url += url.indexOf("?") > -1 ? searchQuery : searchQuery.replace("&", "?"); //note: only first & is replaced with ?
        return DataAccess.get(DataAccess.injectCaller(url, "TaskService.getTasksBySearchQuery")).then(function (data) {
            return (
                data &&
                S25Util.replaceDeep(S25Util.prettifyJson(data, null, { task: true }), {
                    creation_date: S25Util.date.dropTZString,
                    first_date: S25Util.date.dropTZString,
                    respond_by: S25Util.date.dropTZString,
                    due_date: S25Util.date.dropTZString,
                })
            );
        });
    }

    @Timeout
    public static getTaskCount(params: any) {
        params.taskBean.isCount = true;
        return TaskService.getTasks(params).then(function (data) {
            return parseInt(S25Util.propertyGet(data, "tasks")) || 0;
        });
    }

    @Timeout
    public static getTasksByType(type: string, scope: string) {
        return TaskSelectionMap.compute(type).then(function (params) {
            params.scope = scope;
            return TaskService.getTasks(params);
        });
    }

    @Timeout
    public static getTaskSearches() {
        return DataAccess.get(DataAccess.injectCaller("/task_searches.json", "TaskService.getTaskSearches")).then(
            function (data) {
                data = S25Util.prettifyJson(data, null, { task_search: true });
                return data && data.task_searches && data.task_searches.task_search;
            },
        );
    }

    @Timeout
    public static getTodo(id: number | NumericalString, noPretty?: boolean) {
        return DataAccess.get(DataAccess.injectCaller("/todo.json?todo_id=" + id, "TaskService.getTodo")).then(
            function (data) {
                if (!noPretty) {
                    return S25Util.replaceDeep(S25Util.prettifyJson(data), {
                        creation_date: S25Util.date.dropTZString,
                        first_date: S25Util.date.dropTZString,
                        respond_by: S25Util.date.dropTZString,
                        due_date: S25Util.date.dropTZString,
                    });
                } else {
                    return S25Util.replaceDeep(data, {
                        creation_date: S25Util.date.dropTZString,
                        first_date: S25Util.date.dropTZString,
                        respond_by: S25Util.date.dropTZString,
                        due_date: S25Util.date.dropTZString,
                    });
                }
            },
        );
    }

    @Timeout
    public static coerceToTodoDataPromise(todoIdOrData: any) {
        if (S25Util.isInt(todoIdOrData)) {
            return TaskService.getTodo(todoIdOrData);
        } else {
            return jSith.when(todoIdOrData);
        }
    }

    @Timeout
    public static getTodoId() {
        return DataAccess.post(DataAccess.injectCaller("/todo.json", "TaskService.getTodoId")).then(function (resp) {
            return S25Util.propertyGet(S25Util.prettifyJson(resp), "todo_id");
        });
    }

    @Timeout
    public static getNumOverdueTasks() {
        return TaskService.getTasksByType("Overdue", "count").then(function (tasks) {
            if (tasks && tasks.results && tasks.results.tasks) {
                return tasks.results.tasks || 0;
            } else {
                return 0;
            }
        });
    }

    @Timeout
    /**
     * Returns a promise for the number of outstanding tasks for the current user
     * @return {Promise<number>}
     */
    public static async getNumOutstandingTasks(): Promise<number> {
        const tasks = await TaskService.getTasksByType("Outstanding", "count");
        return tasks?.results?.tasks || 0;
    }

    /*
    default: tasks.json?user_id=421&outstanding=T&scope=count
    choosing predefined:
        - Overdue -
        - flagged -
    queryId:
        tasks.json?user_id=421&query_id=1&scope=count
     */
    @Timeout
    public static async getHeaderTaskCount(): Promise<number> {
        const pref = await PreferenceService.getPreferences(["TaskHeaderCount"], "U");
        const searchUrl = pref["TaskHeaderCount"]?.value || "Outstanding";
        let resp = searchUrl.includes("query")
            ? await TaskService.getTasksBySearchQuery(`&${searchUrl}`, [], "count")
            : await TaskService.getTasksByType(searchUrl, "count");

        return resp?.results?.tasks || 0;
    }

    @Timeout
    public static async setHeaderCountPref(prefVal: string) {
        //Format the preference for saving eg. "query_id=1234", "Outstanding", "Overview"
        return PreferenceService.setPreference("TaskHeaderCount", prefVal.replace("&", "")).then(() => {
            dispatchEvent(new Event("updateTaskCount")); //Read in FrameworkApi.updateTasksCount();
        });
    }

    /* PUTs */
    @Timeout
    public static putTask(id: number | NumericalString, data: any) {
        var todoItem = S25Util.propertyGet(data, "todo_item");
        if (todoItem) {
            delete todoItem.scheduler_id;
            delete todoItem.scheduler_name;
            todoItem.due_date =
                S25Util.date.toS25ISODateStr(todoItem.due_date) + "T" + S25Util.date.hourMinuteString(new Date()); //ANG-4426
        }
        return DataAccess.put(DataAccess.injectCaller("/todo.json?todo_id=" + id, "TaskService.putTask"), data).then(
            function (resp) {
                dispatchEvent(new Event("updateTaskCount")); //Read in FrameworkApi.updateTasksCount();
                return S25Util.prettifyJson(resp);
            },
        );
    }

    @Timeout
    public static putTodoAttr(todoIdOrData: any, attrName: string, attrValue: any) {
        return TaskService.coerceToTodoDataPromise(todoIdOrData).then(function (todoData) {
            var todoItem = S25Util.propertyGet(todoData, "todo_item");
            var todoId = S25Util.propertyGetVal(todoItem, "todo_id");
            if (todoItem) {
                todoItem.status = "mod";
                todoItem[attrName] = attrValue;
                return TaskService.putTask(todoId, todoData);
            }
        });
    }

    @Timeout
    public static putTodoAssignment(todoIdOrData: any, contactId: number) {
        return TaskService.putTodoAttr(todoIdOrData, "assigned_to_id", contactId);
    }

    @Timeout
    public static putTodoName(todoIdOrData: any, name: string) {
        return TaskService.putTodoAttr(todoIdOrData, "name", name);
    }

    @Timeout
    public static putTodoDueDate(todoIdOrData: any, dueDate: any) {
        return TaskService.putTodoAttr(todoIdOrData, "due_date", S25Util.date.toS25ISODateStrEndOfDay(dueDate));
    }

    @Timeout
    public static putEventTaskAttr(eventIdOrData: any, approvalId: number, attrName: string, attrValue: any) {
        var eventId = S25Util.isInt(eventIdOrData) ? eventIdOrData : S25Util.propertyGetVal(eventIdOrData, "event_id");
        return EventService.coerceToEventDataPromise(eventIdOrData, ["workflow"]).then(function (eventData) {
            var approval = S25Util.propertyGetParentWithChildValue(eventData, "approval_id", approvalId);
            if (approval) {
                approval.status = "mod";
                approval[attrName] = attrValue;
                eventData.status = "mod";
                return EventService.putEvent(eventId, { events: { event: eventData } });
            }
        });
    }

    @Timeout
    public static putTodoComment(todoId: number, comment: string) {
        return DataAccess.post(
            DataAccess.injectCaller(
                "/taskedit/comment.json?task_id=" + todoId + "&typeId=todo",
                "TaskService.putTodoComment",
            ),
            { root: { comment: comment } },
        );
    }

    @Timeout
    public static putEventTaskComment(eventIdOrData: any, approvalId: number, comment: string) {
        return DataAccess.post(
            DataAccess.injectCaller(
                "/taskedit/comment.json?task_id=" + approvalId + "&typeId=task",
                "TaskService.putEventTaskComment",
            ),
            { root: { comment: comment } },
        );
    }

    @Timeout
    public static putEventTaskDueDate(eventIdOrData: any, approvalId: number, dueDate: any) {
        return TaskService.putEventTaskAttr(
            eventIdOrData,
            approvalId,
            "respond_by",
            S25Util.date.toS25ISODateStrEndOfDay(dueDate),
        );
    }

    @Timeout
    public static createEventTodo(item: any, toDoTemplate?: string) {
        //item: { taskName, eventId, taskComment, dueDate, assignedById, assignedToId }
        if (item.eventId) {
            //wonderfully, event task uses slightly different property names than a plain task...
            var todo: any = { status: "new" };
            todo.cur_todo_state = 1;
            todo.todo_name = item.taskName;
            todo.todo_priority_id = 10;
            todo.due_dt = S25Util.date.toS25ISODateTimeStr(item.dueDate);
            todo.cur_assigned_to_id = item.assignedToId;
            todo.cur_assigned_by_id = item.assignedById;
            todo.read = "F";
            todo.todo_description = item.taskComment;
            todo.todo_subtype = item.subType;
            return LangService.getLang().then(function (lang) {
                var appLang = lang.div.application;
                return BpeService.getEventData(item.eventId).then(function (eventData) {
                    let origEventData = S25Util.deepCopy(eventData);
                    if (eventData) {
                        S25Util.propertyDelete(eventData, [
                            "organization",
                            "category",
                            "requirement",
                            "event_history",
                            "custom_attribute",
                            "role",
                            "profile",
                            "approval",
                            "todo",
                            "event_text",
                        ]);
                        eventData.status = "mod";
                        eventData.todo = todo;

                        const putPromise = EventService.putEvent(item.eventId, { events: { event: eventData } });

                        if (item.subType === Task.SubTypes.cancel) {
                            let preData = S25Util.propertyGet(origEventData, "event");
                            preData = S25Util.array.isArray(preData) ? preData[0] : preData;

                            BpeService.runScenariosAfterChange({
                                preEventData: preData,
                                change: putPromise.then((_) => ({ event_id: item.eventId })),
                                source: "cancelTodos",
                            });
                        }

                        return putPromise.then(
                            function (resp) {
                                if (S25Util.propertyGet(resp, "events")) {
                                    return true;
                                } else {
                                    alert(appLang.event_problem);
                                    return false;
                                }
                            },
                            function (error) {
                                let jsonErr = error?.data && S25Util.prettifyJson(error.data); // part of ANG-4777  can't create toDo
                                if (
                                    !!toDoTemplate &&
                                    jsonErr?.results?.error_details.error_detail?.content ===
                                        "No permission to at least read the event"
                                ) {
                                    alert(
                                        'Could not create "To Do" task by ' +
                                            toDoTemplate +
                                            '  template.  The "Assigned To" user will need at least Edit rights on the event in order for the To Do task to be assigned to them.',
                                    );
                                } else {
                                    //Otherwise throw the error to let caller handle it
                                    throw error;
                                }
                            },
                        );
                    } else {
                        alert(appLang.event_problem);
                        return false;
                    }
                });
            });
        }
    }

    @Timeout
    public static createPlainTodo(item: any) {
        //item: { taskName, taskComment, dueDate, assignedById, assignedToId }
        var todo: any = {};
        return TaskService.getTodoId().then(function (todoId) {
            todo = TaskService.mergePlainTodoObject(item, null, "new");
            todo.todo_item.todo_id = todoId;
            todo.todo_item.type_id = 1;
            todo.todo_item.priority_id = 10;
            todo.todo_item.read = "F";
            todo.todo_item.cur_state_id = 1;
            return TaskService.putTask(todoId, { todo: todo }).then(function (resp) {
                if (S25Util.valueFind(resp, "created", "1")) {
                    return true;
                } else {
                    alert("There was a problem saving your task. Please contact your administrator.");
                    return false;
                }
            });
        });
    }

    @Timeout
    public static setFlag(itemTypeId: number, itemId: number, flagged: boolean) {
        return DataAccess.put(
            "/taskedit/flag.json?itemTypeId=" + itemTypeId + "&itemId=" + itemId + "&val=" + (flagged ? 1 : 0),
        );
    }

    @Timeout
    public static approveCancelRequest(item: { eventId: number }, newState: Task.State) {
        return EventStateChangeService.changeState(item.eventId, 99, null, "task").then(() => {
            return [{ success: { overallState: newState }, item: item }];
        });
    }

    /**
     *
     * @param items
     * @param newState
     * @param eventId
     * @param taskIds
     *
     * Steps in this function
     * 1. Get the eventId and event data - we only handle one at a time
     * 2. If doing 'approve all' for a task, get the other taskIds for the same object
     * 3. Check if the task has been denied by another user - give bail option
     * 4. Perform task action (assign,approve,cancel,deny etc)
     * 5. Extract messages from the response
     * 6. Send Emails
     */
    @Timeout
    public static async changeEventTaskState(
        items: TaskChange[],
        newState: Task.State,
        eventId: number,
        taskIds: Set<number>,
    ): Promise<EventTaskStateChange[]> {
        const lang = await LangService.getLang(); //.then(function (lang) {
        const appLang = lang.div.application;
        let results: EventTaskStateChange[] = [];

        const [currentContactId, preEventData, isChained] = await Promise.all([
            UserprefService.getContactId(),
            eventId && BpeService.getEventData(eventId),
            TaskTiersService.isWorkflowChained(),
        ]);

        if (preEventData) {
            let denialCount = 0;
            let alertText = "";
            let deniedTask: Task.Object;

            //From task search results we can use 'approve all' option to approve a task for all occurrences get taskIds for other occurrence's requests
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (
                    item.eventId &&
                    item.itemCount > 1 &&
                    [Task.Ids.Assign, Task.Ids.UnAssign].includes(item.itemTypeId)
                ) {
                    preEventData.approval
                        .filter(
                            (approval: S25Event.Workflow.Task) =>
                                approval.approval_id !== item.itemId &&
                                approval.object_type === item.objectType &&
                                approval.object_id === item.objectId &&
                                approval.approval_contact?.some(
                                    (contact) =>
                                        contact.approval_contact_id === currentContactId &&
                                        contact.approval_contact_state === Task.States.InProgress,
                                ),
                        )
                        .forEach((app: S25Event.Workflow.Task) => {
                            taskIds.add(app.approval_id);
                        });
                } else if (
                    item.eventId &&
                    (item.todoType || item.isTodo) &&
                    item.todoSubType === Task.SubTypes.cancel &&
                    newState === Task.States.Completed
                ) {
                    //ANG-3162 Deal with event cancel requests, newState 2 is completed. Event is now cancelled no need to do any other task actions
                    const evCancelResp = await TaskService.approveCancelRequest(item, newState);
                    //Bare-bones response object eventStateChange handles error communication
                    let retVal: EventTaskStateChange = {
                        alertText: "",
                        error: "",
                        item: undefined,
                        overallState: evCancelResp[0].success.overallState,
                        success: true,
                    };

                    return [retVal];
                }
            }

            //If another user has denied the task, confirm before acting. Important for assignment policy
            taskIds.forEach((taskId) => {
                const previousDenials = getDeniedUser(preEventData, taskId);
                if (previousDenials?.length > 0) {
                    const object = preEventData.approval.find(
                        (approval: S25Event.Workflow.Task) => approval.approval_id === taskId,
                    );
                    deniedTask = TaskNormalizeUtil.normalizeTaskData(object);
                    alertText += `${object.approval_name} has been denied by ${previousDenials.map((t: Contact) => t.approval_contact_name).join(", ")}.\n`;
                    denialCount++;
                }
            });

            if (denialCount > 0) {
                alertText += "Are you sure you want to approve it?";
                const confirmAction = await AlertService.confirm(alertText);
                if (!confirmAction) {
                    const retVal: EventTaskStateChange = {
                        alertText: alertText,
                        success: undefined,
                        error: "deny",
                        overallState: null,
                        item: deniedTask, //TODO: handle this for multiple tasks
                    };
                    return Promise.resolve([retVal]);
                }
            }
        }

        const taskChangePromise = TaskEditService.changeStatus(Array.from(taskIds), newState);

        const [taskChangeResp, err] = await S25Util.Maybe(taskChangePromise);
        if (err) {
            S25Util.showError(err, appLang.event_problem);
            return [
                {
                    error: "EventProblem",
                    item: preEventData.approval
                        .filter((approval: S25Event.Workflow.Task) => approval.approval_id !== items[0].itemId)
                        .map(TaskNormalizeUtil.normalizeTaskData),
                } as EventTaskStateChange,
            ];
        }

        let skipUpdatingScheduled = true;
        items.forEach((item) => {
            if (item.itemTypeId !== Task.Ids.FYI) skipUpdatingScheduled = false;
            //Find the relevant response task
            let completedTask = taskChangeResp.data.find((task) => task.taskId === S25Util.parseInt(item.itemId));
            //Extracts the messages for this specific task
            const taskMessages = S25Util.array
                .forceArray(taskChangeResp.messages)
                .filter((msg) => msg.objectType === 10 && item.itemId === msg.objectId)
                .map((msg) => {
                    return msg.message;
                });

            //cancelled task doesn't have success/error flags so treat it separately
            if (newState === Task.States.Cancelled) {
                const result: EventTaskStateChange = {
                    alertText: taskMessages.join("\n"),
                    success: completedTask.overallState === Task.States.Cancelled,
                    error: undefined,
                    overallState: completedTask.overallState,
                    item: completedTask,
                };
                results.push(result);
            } else {
                const result: EventTaskStateChange = {
                    alertText: taskMessages.join("\n"),
                    success: completedTask.success, //!error.error, //&& { success: "success", contacts: respApprovalContacts },
                    error: completedTask.error, //taskMessages.join("\n"),
                    overallState: completedTask.overallState,
                    item: completedTask,
                };
                results.push(result);
            }
        });

        const alertText = extractMessages(taskChangeResp);
        alertText && alert(alertText);

        BpeService.runScenariosAfterChange({
            preEventData,
            change: Promise.resolve({ event_id: eventId }),
            source: "task",
            noChange: skipUpdatingScheduled,
        });

        if (isChained) {
            BpeService.getEventData(eventId, true).then((evResp) => {
                TaskTiersService.notifyOnNewTier(evResp?.approval, preEventData?.approval);
            });
        }

        return results;
    }

    @Timeout
    public static changeNonEventTask(item: { itemId: NumericalString }, newState: Task.State) {
        return LangService.getLang().then(function (lang) {
            let appLang = lang.div.application;
            return TaskService.getTodo(item.itemId, true).then(function (todoData) {
                if (todoData && todoData.todo && todoData.todo.todo_item) {
                    todoData.todo.todo_item.status = "mod";
                    todoData.todo.todo_item.read = "T";
                    todoData.todo.todo_item.cur_state_id = newState;
                    todoData.todo.todo_item.completed = S25Util.date.toS25ISODateStr(new Date());
                    return TaskService.putTask(item.itemId, todoData).then(
                        function (todoResp) {
                            if (S25Util.valueFind(todoResp, "modified", 1)) {
                                return [
                                    {
                                        success: { overallState: newState },
                                        item: item,
                                        alertText: "",
                                        error: "",
                                        overallState: newState,
                                    },
                                ];
                            } else {
                                alert(appLang.event_problem);
                                return [{ error: "EventProblem", item: item }];
                            }
                        },
                        function () {
                            //to-do put error
                            alert(appLang.event_problem);
                            return [{ error: "EventProblem", item: item }];
                        },
                    );
                }
            });
        });
    }

    /**
     * The best and most complete way to change the status of a task.
     * @param items //An array of task items
     * @param newState
     */
    @Timeout
    public static async changeTask(
        items: TaskChange | TaskChange[],
        newState: Task.State,
    ): Promise<EventTaskStateChange[]> {
        let evPromise, nonEvTaskPromise;
        newState = S25Util.parseInt(newState);
        items = S25Util.array.forceArray(items) as TaskChange[];

        const eventTasks = items.filter((item) => item.eventId);
        const nonEventTask = items.find((item) => !item.eventId);

        nonEvTaskPromise = nonEventTask
            ? TaskService.changeNonEventTask({ itemId: String(nonEventTask.itemId) as NumericalString }, newState)
            : Promise.resolve([]);

        /*
        Combining multiple requests into 1
        when there is a request in progress for the event,
        Instead of starting the new process build a queue.

        Items are removed from the queue as soon as the process is started
        see if this event is being acted upon by another task eg. clicking approve several times in a row.
        */
        let actionQueueHash: string;

        if (eventTasks?.length > 0) {
            const eventId = S25Util.array.unique(eventTasks.map((item) => item.eventId))[0];
            const evTaskIds = new Set(eventTasks.map((item) => item.itemId));
            actionQueueHash = eventId + "-" + newState;

            if (taskActionQueue[actionQueueHash]?.inProgressPromise) {
                //already running a process for this event add request to the queue
                eventTasks.forEach((item) => {
                    if (!taskActionQueue[actionQueueHash].items.includes(item)) {
                        taskActionQueue[actionQueueHash].items.push(item);
                    }
                });

                return taskActionQueue[actionQueueHash].inProgressPromise;
            } else {
                //Trigger the task completion
                evPromise = TaskService.changeEventTaskState(eventTasks, newState, eventId, evTaskIds);
                //Nothing currently running, start the process
                taskActionQueue[actionQueueHash] = {
                    ...taskActionQueue[actionQueueHash],
                    inProgressPromise: evPromise,
                    items: [],
                };
            }
        }

        return Promise.all([evPromise, nonEvTaskPromise]).then((resp) => {
            //Mark the queue as not in progress and start the next process in the queue
            delete taskActionQueue[actionQueueHash]?.inProgressPromise;
            if (taskActionQueue[actionQueueHash]?.items?.length) {
                TaskService.changeTask(taskActionQueue[actionQueueHash].items, newState);
            }
            return resp.flat().filter((item) => item);
        });
    }

    /* Misc & Helpers */
    public static scopeToDataRequest(scope: any) {
        return S25Util.merge({}, ListService.scopeToDataRequest(scope), {
            //attach list elements (pagination stuff, etc)
            taskBean: scope.taskBean,
        });
    }

    public static hasApproveDenyPerms(approval: any, currentContactId: number) {
        return S25Util.array.isIn(
            (approval && approval.approval_contact) || [],
            "approval_contact_id",
            currentContactId,
        );
    }

    public static formTaskContactString(hasPerms: boolean, currentContactId: number, approval: any) {
        return (
            (hasPerms
                ? "You" + (approval && approval.approval_contact && approval.approval_contact.length > 1 ? " and " : "")
                : "") +
            S25Util.array
                .forceArray(approval?.approval_contact)
                .map(function (contact: any) {
                    if (!hasPerms || parseInt(contact.approval_contact_id) !== currentContactId) {
                        return contact.approval_contact_name;
                    }
                })
                .sort()
                .join("; ")
        );
    }

    public static formSingleContactString(assignedToName: string, assignedToId: string, currentContactId: number) {
        return TaskService.formTaskContactString(parseInt(assignedToId) === currentContactId, currentContactId, {
            approval_contact: [{ approval_contact_id: assignedToId, approval_contact_name: assignedToName }],
        });
    }

    public static getApprovalContact(approvalContacts: any, contactId: number): any {
        var contact = S25Util.array.forceArray(approvalContacts).filter(function (obj: any) {
            return parseInt(obj.approval_contact_id) === contactId;
        });
        return contact && contact.length && contact[0];
    }

    public static extractFirstApprovalContact(approval: any) {
        if (approval) {
            var approvalContacts = approval.approval_contact || [];
            if (approvalContacts && approvalContacts.length) {
                var len = approvalContacts.length;
                //return first contact that is not fyi
                for (var i = 0; i < len; i++) {
                    if (approvalContacts[i].notification_type_id !== "1") {
                        //not FYI
                        return approvalContacts[i];
                    }
                }
                return approvalContacts[0]; //else just return first contact
            }
        }
    }

    public static extractApprovalTypeInfo(approval: any, currentContactId?: number) {
        //prefer to use user's type id, fall back to first contact
        var currentContactApproval = TaskService.getApprovalContact(approval.approval_contact || [], currentContactId);
        var firstContact;
        if (!currentContactApproval) {
            firstContact = TaskService.extractFirstApprovalContact(approval);
        }
        return {
            typeId:
                currentContactApproval?.notification_type_id ||
                firstContact?.notification_type_id ||
                approval?.approval_type_id,
            typeName:
                currentContactApproval?.notification_type_name ||
                firstContact?.notification_type_name ||
                approval?.approval_type_name,
        };
    }

    public static getTaskState(task: S25Event.Workflow.Task) {
        //If denied for anyone denied for everyone, otherwise use parent state
        if (task?.approval_contact?.some((contact) => contact.approval_contact_state === Task.States.Denied)) {
            return Task.States.Denied;
        }
        return task?.approval_state || Task.States.InProgress;
    }

    public static taskStateToStateText(stateId: Task.State, taskTypeId: Task.Id): StateName {
        stateId = S25Util.parseInt(stateId);
        taskTypeId = S25Util.parseInt(taskTypeId);
        let stateText: StateName;
        if (stateId === Task.States.Completed) {
            switch (taskTypeId) {
                case 1:
                    stateText = "Acknowledged";
                    break;
                case 3:
                    stateText = "Assigned";
                    break;
                case 4:
                    stateText = "UnAssigned";
                    break;
                case 5:
                    stateText = "Completed";
                    break;
                default:
                    stateText = "Approved";
                    break;
            }
        } else if (stateId === Task.States.Denied) {
            switch (taskTypeId) {
                case 5:
                    stateText = "Ignored";
                    break;
                default:
                    stateText = "Denied";
                    break;
            }
        } else if (stateId === Task.States.Cancelled) {
            stateText = "Cancelled";
        } else if (stateId === Task.States.Various) {
            stateText = "Various";
        } else {
            stateText = "In Progress";
        }
        return stateText;
    }

    public static mergePlainTodoObject(item: any, origItem: any, statusOverride: any) {
        //item: { taskName, taskComment, dueDate, assignedById, assignedToId }. origItem: json converted xml of some task
        origItem = (origItem && origItem.todo) || { todo_item: {} }; //either orig to-do_item from some existing to-do, or a new one
        S25Util.merge(origItem.todo_item, {
            name: item.taskName,
            comment: item.taskComment,
            due_date: S25Util.date.toS25ISODateTimeStr(item.dueDate),
            assigned_by_id: item.assignedById,
            assigned_to_id: item.assignedToId,
        });
        if (statusOverride) {
            origItem.todo_item.status = statusOverride;
        }
        return origItem;
    }

    public static taskSearchCriteriaGETTransform(data: any) {
        //form api data into expected data, as seen in s25-advanced-search-util.js under task: {}
        var ret = S25Util.deepCopy(data);
        ret.step = [S25Util.deepCopy(AdvancedSearchUtil.s25SearchAdvancedStepTemplate.task)[1000]];
        ret.step.status = "est";
        ret.step[0].dateBean[0].step_param[0].from_dt =
            data.step[0].step_param[0].between_start_dt || data.step[0].step_param[0].from_dt || 0;
        ret.step[0].dateBean[0].step_param[0].until_dt =
            data.step[0].step_param[0].between_end_dt || data.step[0].step_param[0].until_dt || 0;

        ret.step[0].states.outstanding = S25Util.valueFind(data, "outstanding", "T");
        ret.step[0].states.completed = S25Util.valueFind(data, "approved", "T");
        ret.step[0].states.denied = S25Util.valueFind(data, "denied", "T");
        ret.step[0].states.cancelled = S25Util.valueFind(data, "cancelled", "T");
        ret.step[0].states.unread = S25Util.valueFind(data, "unread_only", "T");

        ret.step[0].types.todo = S25Util.valueFind(data, "step_type_id", 1010);
        ret.step[0].types.vcal = S25Util.valueFind(data, "step_type_id", 1015);
        ret.step[0].types.authorization = S25Util.valueFind(data, "step_type_id", 1020);
        ret.step[0].types.fyi = S25Util.valueFind(data, "step_type_id", 1021);
        ret.step[0].types.assignment = S25Util.valueFind(data, "step_type_id", 1022);

        var assignedTo = [],
            assignedToHash: any = {},
            assignedFrom = [],
            assignedFromHash: any = {};
        for (var i = 0; i < data.step[0].step_param.length; i++) {
            var assignedToId = parseInt(data.step[0].step_param[i].assigned_to_id);
            var assignedFromId = parseInt(data.step[0].step_param[i].assigned_by_id);
            if (assignedToId && !assignedToHash[assignedToId]) {
                assignedToHash[assignedToId] = true;
                assignedTo.push({ itemName: data.step[0].step_param[i].assigned_to_name, itemId: assignedToId });
            }
            if (assignedFromId && !assignedFromHash[assignedFromId]) {
                assignedFromHash[assignedFromId] = true;
                assignedFrom.push({ itemName: data.step[0].step_param[i].assigned_by_name, itemId: assignedFromId });
            }
        }
        ret.step[0].contactBeans[0].step_param = assignedTo;
        ret.step[0].contactBeans[1].step_param = assignedFrom;
        return ret;
    }

    public static taskSearchCriteriaPUTTransform(searchModelIn: SearchCriteria.Model) {
        var searchModel = S25Util.deepCopy(searchModelIn);
        var types = [],
            states = [],
            unread = !!searchModel.step[0].states.unread,
            dateBean = searchModel.step[0].dateBean[0].step_param[0];
        var assignedTo = S25Util.deepCopy(searchModel.step[0].contactBeans[0].step_param);
        var assignedFrom = S25Util.deepCopy(searchModel.step[0].contactBeans[1].step_param);
        var StepTypeNameMap: any = {
            1010: "Todos",
            1015: "vCalendar todos",
            1020: "Approvals",
            1021: "FYIs",
            1022: "Assignments",
        };
        var paramTemplate = S25Util.extend({ unread_only: unread ? "T" : "F" }, dateBean);

        searchModel.step[0].types.todo && types.push(1010);
        searchModel.step[0].types.vcal && types.push(1015);
        searchModel.step[0].types.authorization && types.push(1020);
        searchModel.step[0].types.fyi && types.push(1021);
        searchModel.step[0].types.assignment && types.push(1022);

        searchModel.step[0].states.outstanding && states.push(1);
        searchModel.step[0].states.completed && states.push(2);
        searchModel.step[0].states.denied && states.push(3);
        searchModel.step[0].states.cancelled && states.push(4);

        var steps = new Array(types.length * states.length);
        for (var i = 0; i < types.length; i++) {
            for (var j = 0; j < states.length; j++) {
                var step: any = {
                    step_number: i * states.length + j + 1,
                    step_type_id: types[i],
                    step_type_name: StepTypeNameMap[types[i]],
                };
                var params = [];
                assignedTo.length === 0 && assignedTo.push(undefined); //enter loop at least once in case there are no assigned to but there are assigned from
                assignedFrom.length === 0 && assignedFrom.push(undefined); //enter loop at least once
                for (var k = 0; k < assignedTo.length; k++) {
                    for (var l = 0; l < assignedFrom.length; l++) {
                        var param = S25Util.deepCopy(paramTemplate);

                        if (states[j] === 1) {
                            param.outstanding = "T";
                        } else if (states[j] === 2) {
                            param.approved = "T";
                        } else if (states[j] === 3) {
                            param.denied = "T";
                        } else if (states[j] === 4) {
                            param.cancelled = "T";
                        }

                        if (!S25Util.isUndefined(assignedTo[k])) {
                            param.assigned_to_id = assignedTo[k].itemId;
                            param.assigned_to_name = assignedTo[k].itemName;
                        }

                        if (!S25Util.isUndefined(assignedFrom[l])) {
                            param.assigned_by_id = assignedFrom[l].itemId;
                            param.assigned_by_name = assignedFrom[l].itemName;
                        }

                        params.push(param);
                    }
                }

                step.step_param = params;
                steps[i * states.length + j] = step;
            }
        }

        searchModel.step = steps;
        return searchModel;
    }

    public static getTaskData(
        taskIds: number[],
        eventId: number,
        isTodo: boolean,
        contactId?: number,
    ): Promise<Task.Object[]> {
        // taskIds = S25Util.array.forceArray(taskIds);
        let tasks: Task.Object[] = [];
        let dataPromise = isTodo
            ? taskIds[0] && TaskService.getTodo(taskIds[0])
            : eventId && EventService.getEventInclude(eventId, ["workflow"], null, true);

        if (isTodo && taskIds[0]) {
            return TaskService.getTodo(taskIds[0]).then((resp) => {
                return [TaskNormalizeUtil.todoToObj(resp?.todo?.todo_item)];
            });
        } else {
            return EventService.getEventInclude(eventId, ["workflow"], null, true).then((resp) => {
                taskIds.forEach((taskId) => {
                    let taskNode = S25Util.propertyGetParentsWithChildValue(resp, "approval_id", taskId) || [];
                    tasks.concat(
                        taskNode.map((node) => {
                            node.eventId = eventId;
                            node.eventName = resp.event_name;
                            return TaskNormalizeUtil.eventTaskToObj(node);
                        }),
                    );
                });
                return tasks;
            });
        }
    }

    public static getTaskById(id: number) {
        return DataAccess.get(DataAccess.injectCaller("/task.json?task_id=" + id, "TaskService.getTaskById")).then(
            function (data) {
                return data?.tasks?.task;
            },
        );
    }
}

let getDeniedUser = function (eventData: EventData, taskId: number) {
    const contApproval = S25Util.array.getByProp(eventData.approval, "approval_id", taskId) as S25Event.Workflow.Task;
    return (
        contApproval?.approval_contact.filter((cont: Contact) => {
            return cont.approval_contact_state === Task.States.Denied && cont.read_state === "T";
        }) || []
    );
};

//Extracts messages from micro event task update response and prepares them for an alert
//Most likely messages indicate the space or resource could not be assigned
function extractMessages(taskUpdateResp: TaskUpdateI) {
    const messages = S25Util.array
        .forceArray(taskUpdateResp?.messages)
        .filter((msg) => !!msg.message && ["EV_I_RESOUT", "EV_I_SPACECON", "EV_W_SPACECON"].includes(msg.reason));
    return messages
        .map((msg) => msg.message)
        .join("\n")
        .replace("[rsrv]", "");
}
